var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { staticClass: "Contextclass", attrs: { prop: "reason" } },
    [
      _c("v-textarea", {
        attrs: {
          "hide-details": "auto",
          label: "申请原因",
          placeholder: "售后申请说明，500字内",
          outlined: "",
          dense: true,
          height: 120,
          maxlength: "500",
          counter: ""
        },
        model: {
          value: _vm.form.reason,
          callback: function($$v) {
            _vm.$set(
              _vm.form,
              "reason",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "form.reason"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }