<template>
  <el-form-item prop="reason" class="Contextclass">
    <v-textarea
      v-model.trim="form.reason"
      hide-details="auto"
      label="申请原因"
      placeholder="售后申请说明，500字内"
      outlined
      :dense="true"
      :height="120"
      maxlength="500"
      counter
    ></v-textarea>
</el-form-item>
</template>

<script>
export default {
  props: {
    form: Object
  }
}
</script>

<style>

</style>